<template>
  <div class="cta rellax" v-parallax="0.3">
    <router-link to="/contact">
      <img class="svg" src="@/assets/images/cta.svg" alt="Contact me">
    </router-link>
  </div>
</template>

<script>
export default {
name: 'Cta',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
.cta{
  // margin-bottom: -7em;
  bottom: -5rem;
}
</style>