<template>
  <main class="main test">

    <section class="container section intro v-animate mb-0">
     <div>
      
      <!-- <h1>Test Page 1</h1> -->

      <p>
         <router-link to="/test-child">Test Child Link</router-link>
      </p>
      

      <div class="product">
        <h4>Product List from Store JS</h4>
        <ul>
          <li v-for="product in saleProducts" :key="product.name">
            <span class="name">{{product.name}}</span>
            <span class="price">${{product.price}}</span>
          </li>
        </ul>
        <button v-on:click="reducePrice(4)">Reduce Price</button>
        <button v-on:click="specialPrice(4)">Special Price</button>
      </div>

      <div v-bind:class="[show ? blurClass : '', backClass]"><h4>{{demo2}}</h4></div>
      <button v-on:click="show = !show">Toggle</button>

      <transition name="fade">
        <div v-if="show">
          <h4>{{demo}}</h4>
        </div>
      </transition>

      

      <div>
        <button v-on:click="watchList.push(watchList.length+1)">Add to WatchList</button>
        <ul ref="watchList">
          <li v-for="list in watchList" v-bind:key="list.id">{{ list }}</li>
        </ul>
      </div>

      <h4>Test for Methods and Cumputed Property</h4>
      <ul class="items" v-for="item in items" v-bind:key="item.id">
        <li>
          <span>Name: {{item.name}}</span>
          <span>Price: {{item.price}}</span>
          <span>Quantity: {{item.quantity}}</span>
          <span><input type="number" v-model="item.quantity"></span>
          <span>Sub: {{item.price * item.quantity}}</span>
        </li>
      </ul>

      <p>Total: {{totalPrice}}</p>
      <p>Total with tax: {{totalPriceWithTax}}</p>
      <p><button v-on:click="doBuy()">Purchase</button></p>

     </div>
    </section>

    <TestChild v-bind:ppp="items" />

    <Contact />
   
  </main>
</template>

<script>
import Contact from './Contact.vue'
import TestChild from './Page-TestChild.vue'
import {mapActions} from 'vuex'
import {mapGetters} from 'vuex'

var numbers = [1,2,3,4,5];
var result = numbers.map(function(num) {
  return num * 2 + "mapped";
})
console.log("maptest: "+result);

export default {
  name: 'Test',
  components: {
    Contact,
    TestChild
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String,
  },
  data() {
    return {
      demo: '{demo} Transion Test Starts',
      demo2: '{demo2}Blur Test Starts',
      show: false,
      backClass: 'bk',
      blurClass: 'blur',
      testprop: '{testprop}This is test property from parent-test to child-test',
      items : [
        { id:1, name: 'Udon', price: 100, quantity: 2 },
        { id:2, name: 'Curry', price: 203, quantity: 1 },
        { id:3, name: 'Gyoza', price: 807, quantity: 1 }
      ],
      watchList: []
    }
  },
  watch: {
    watchList: function() {
      console.log('Normal', this.$refs.watchList.offsetHeight)
      this.$nextTick(function() { 
        console.log('nextTick:', this.$refs.watchList.offsetHeight)
      })
    }
  },
  computed: {
    products(){
      return this.$store.state.products
    },
    // saleProducts(){
    //   return this.$store.getters.saleProducts
    // },
    //use map
    ...mapGetters([
      'saleProducts',
      'specialProducts'
    ]),
    totalPrice(){
      return this.items.reduce((sum, item) => sum + (item.price * item.quantity),0)
    },
    totalPriceWithTax(){
      return Math.floor(this.totalPrice * 1.08)
    }
  },
  methods: {
    // direct mutation not recommended
    // reducePrice: function(){
    //   this.$store.commit('reducePrice')
    //   // this.$store.state.products.forEach(product => {
    //   //   product.price -= 1
    //   // })
    // },
    // better to use action than mutation
    // reducePrice: function(amount){
    //   this.$store.dispatch('reducePrice', amount)
    // },
    ...mapActions([
      'reducePrice',
      'specialPrice'
    ]),
    doBuy: function(){
      window.alert('The total is $' + this.totalPriceWithTax + " Thank you")
    }
  },
  mounted(){
    this.observer()
    this.scroll()
  }
}

</script>

<style scoped lang="scss">

button:not(:first-of-type){
  margin-left: 22px;
}
.product{
  span{
    margin-right: 1em;
  }
}
p{
  margin-bottom: .5em;
}
.items{
  margin-bottom: .5em;
  span{
    margin-right: 1em;
  }
}
h1{
  margin-bottom: .4em;
}
.container{
  min-height: auto !important;
}
.fade-enter-active, .fade-leave-active{
  transition: opacity 1s ease-out;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.bk {
  transition: all .1s ease-out;
}
.blur {
  filter: blur(2px);
  opacity: .4;
}

.test{
  background: #085;
}
$n: 10px;
button{
  margin-bottom: 30+$n;
}
</style>