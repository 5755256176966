<template>
  <main class="main home">

    <!-- <NavExtra /> -->
    <Cta />
    
    <section class="container section intro v-animate"> 
      <div class="intro-inner h-1">
         {{firstName}}
        <p class="text-outline v-animate v-move-in">I’m Jiro Takahashi.</p>
        <p class="v-animate v-move-in delay-1">Full-Stack Designer who makes business stands out with 360* Design experience</p>
      </div>
    </section>

    <section class="container section works">
      <h1 class="v-animate v-move-in v-draw-line-bottom">Selected Works</h1>
    
      <div class="showcase l-grid">
        
        <router-link to="/work-template" class="item i-m-l v-animate v-fade-in" v-parallax="0">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        
        <router-link to="/work-template" class="item i-s-r v-animate v-fade-in" v-parallax="-0.2">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>

        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in" v-parallax="-0.2">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>

        <router-link to="/work-template" class="item i-s-l v-animate v-fade-in" v-parallax="-0.1">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>

        <router-link to="/work-template" class="item i-m-r v-animate v-fade-in" v-parallax="-0.24">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>

        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in" v-parallax="0">
          <h2 class="title">Upflex Website</h2>
          <div class="visual"><img src="@/assets/images//work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link> 
      </div>
      
      <div class="a-center a-m-left">
        <router-link to="/works" class="btn">
        All works<div class="arrow"></div>
        </router-link>
      </div>
    </section>


    <section class="container section about">
      <h1 class="about-inner v-animate v-move-in v-draw-line-bottom">About Me</h1>
      <div class="l-grid v-animate v-fade-in">
        <div class="col-l offset">
          <p class="text-title">Lorem ipsum dolor sit amet, are consetetur sadipscing elitr. </p>
        </div>
        <div class="col-r">
          <p>Hehehe,,, Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr</p>

          <router-link to="/about" class="btn">
          More About<div class="arrow"></div>
          </router-link>
        </div>
      </div>
    </section> 


    <section class="container-fluid section">
      <div class="ticker ticker-main">
        <span>Art Direction</span><span>Motion Graphics</span><span>Video Editing</span><span>Frontend</span><span>Backend</span><span>Illustration</span><span>Marketing</span><span>UI/UX</span><span>Curry</span><span>Art Direction</span>
      </div>
    </section>

    <Contact />
    
  </main>
</template>

<script>
import Contact from './Contact.vue'
import Cta from './Cta.vue'
// import NavExtra from'./NavExtra.vue'

export default {
  name: 'Home',
  components: {
    Contact,
    Cta,
    // NavExtra
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String
  },
  data() {
    return {
      
    }
  },
  mounted(){
    // this.test()
    // this.overlay()
    // this.$nextTick(function () {
    //   this.observer()
    // })
    this.observer()
    this.scroll()
    this.hoge = "Mounted Page Home"
    console.log('mounted: '+ this.hoge)
      
  } 
}


</script>

<style scoped lang="scss">

</style>