<template>
  <div id="app">

    <div class="grain"></div>

    <div id="preloader-overlay" class="overlay overlay-preloader">
      <div class="overlay-content container">
        <div class="loading l-grid v-animate v-fade-in">
          <h1 class="col-l h-4 name">Jiro Takahashi</h1>
          <div class="col-r position">
            <div class="position-inner">
              <span class="s1">Creative Director</span>
              <span class="s2">UI/UX Designer</span>
              <span class="s3">Front-end Developer</span>
            </div>
          </div>
        </div> 
        <div class="progress"><span data-target="100">0</span>%</div>
      </div>
    </div>

    <div class="wrapper">
      <Aside :observer="observer" />
      <Header :observer="observer" />
      <!-- <transition name="fade" mode="out-in"> -->
        <router-view :observer="observer" :scroll="scroll" :accordion="accordion" :test="test" :firstName="firstName"></router-view>
      <!-- </transition> -->
      <!-- <PageHome /> -->
      Parent: {{firstName}}
      <!-- <PageAbout :observer="observer" /> -->
      <!-- <PageWorkTemplate /> -->
      <!-- <PageContact /> -->
      <Footer />
    </div>
  </div>
</template>

<script>
import Aside from './components/Aside.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

// import PageHome from './components/Page-Home.vue'
// import PageAbout from './components/Page-About.vue'
// import PageWorkTemplate from './components/Page-Work-Template.vue'
// import PageContact from './components/Page-Contact.vue'



export default {
  name: 'App',
  components: {
    Aside,
    Header,
    Footer,
    // PageHome,
    // PageAbout,
    // PageWorkTemplate,
    // PageContact
  },
  props: {
    // observer: Function,
    // accordion: Function

  },
  data() {
    return {
      name: 'hogehoge..',
      firstName: 'Jiro',
      lastName: 'Takahashi',
      hoge: "HOGE"
    }
  },
  methods:{
    showEl : function(){
      console.log('aho')
    },
    showhoge: function(){
      console.log(this.hoge);
    },
    test: function(){
      alert('test in Parent!')
    },
    preloader: function(){
      const loading = document.querySelector('#preloader-overlay');
      const wrapper = document.querySelector('body .wrapper');

      if (loading){
        window.addEventListener('load',function(){
          const counter = document.querySelector('.progress span');
          const speed = 300; 
          const timer = 600;

          (() => {
            const updateCount = () => {
              const target = counter.getAttribute('data-target');
              const count = +counter.innerText;
              const inc = target / speed;

              if (count < target) {
                counter.innerText = `${Math.ceil(count + inc)}`;
                setTimeout(updateCount, 1);
              } else {
                console.log('Loaded ..');
                counter.innerText = target;
                setTimeout(() => {
                  document.querySelector('body').classList.add("loaded");
                  wrapper.classList.add("show");
                }, timer);   
              }
            };
            updateCount();
          })();
        });
      }
      else{
        console.log("No loading");
        wrapper.classList.add("show");
      }
    },
    overlay: function(){
      let navFlag = false;
      let worksFlag = false;
      const body = document.querySelector('body');
      const worksBtn = document.querySelector('#nav-works');
      const workBtnLinks = [...document.querySelectorAll('#nav-works .link')];
      const workLinks = document.querySelectorAll('#archives-overlay .link');
      const archivesOverlay = document.querySelector('#archives-overlay');
      const menuBtn = document.querySelector('#menu-btn');
      const menuOverlay = document.querySelector('#menu-overlay');
      const menuLinks = document.querySelectorAll('#menu-overlay .nav-menu');
        

      // Works Overlay
      worksBtn.addEventListener("click", (e) => {
        e.preventDefault();
        if (worksFlag){
          console.log('works 1');
          archivesOverlay.style.height = "0%";
          archivesOverlay.style.opacity = "0";
          body.classList.remove('open-overlay');
          menuBtn.classList.remove('active');
          workBtnLinks[0].classList.remove('d-none');
          workBtnLinks[1].classList.add('d-none');
          worksFlag = false;
          // navFlag = false;
        }
        else {
          console.log('works 2');
          archivesOverlay.style.height = "100%";
          archivesOverlay.style.opacity = "1";
          body.classList.add('open-overlay');
          menuBtn.classList.add('active');
          workBtnLinks[0].classList.add('d-none');
          workBtnLinks[1].classList.remove('d-none');
          worksFlag = true;
          // navFlag = true;
        }
        // worksFlag =! worksFlag;
        // navFlag =! navFlag;
        archivesOverlay.scrollTo(0,0);
        console.log('archive is clicked: ');
        console.log('worksFlag: '+worksFlag);
        console.log('navFlag is: '+navFlag);
        // console.log(this);
      });

      // Work Links
      workLinks.forEach(el => {
        // console.log(el);
        el.addEventListener("click", () => {
          console.log('work link clicked');
          archivesOverlay.style.height = "0%";
          archivesOverlay.style.opacity = "0";
          body.classList.remove('open-overlay');
          menuBtn.classList.remove('active');
          worksFlag = false;
          navFlag = false;
          workBtnLinks[0].classList.remove('d-none');
          workBtnLinks[1].classList.add('d-none');
          
        });
      });
      
      // Menu Overlay
      menuBtn.addEventListener("click", () => {
        if (worksFlag){
          console.log('menu 1');
          archivesOverlay.style.height = "0%";
          archivesOverlay.style.opacity = "0";
          workBtnLinks[0].classList.toggle('d-none');
          workBtnLinks[1].classList.toggle('d-none');
          body.classList.remove('open-overlay');
          menuBtn.classList.remove('active');  
          workBtnLinks[0].classList.remove('d-none');
          workBtnLinks[1].classList.add('d-none');
          // navFlag = false;
          worksFlag = false;
        }
        else if (navFlag){
          console.log('menu 2');
          menuOverlay.style.height = "0%";
          menuOverlay.style.opacity = "0";
          body.classList.remove('open-overlay');
          menuBtn.classList.remove('active');
          navFlag = false;
        }
        else{
          console.log('menu 3');
          menuOverlay.style.height = "100%";
          menuOverlay.style.opacity = "1";
          body.classList.add('open-overlay');
          menuBtn.classList.add('active');
          navFlag = true;
        }
        console.log('menubutton is clicked: ');
        console.log('worksFlag: '+worksFlag);
        console.log('navFlag: '+navFlag);
        // console.log(this);
        // navFlag =! navFlag;
      });

      // Menu Links
      menuLinks.forEach(el => {
        // sconsole.log(el);
        el.addEventListener("click", () => {
          // e.stopPropagation();
          console.log('menu link click..');
          console.log('menu link clicked');
          menuOverlay.style.height = "0%";
          menuOverlay.style.opacity = "0";
          body.classList.remove('open-overlay');
          menuBtn.classList.remove('active');
          navFlag = false;
        });
      });
      
    },
    observer: function(){
      console.log('observer!')
      const sections = document.querySelectorAll('.v-animate');
      // console.log(sections);
      const navPageTop = document.querySelector('#nav-pagetop');
      const navWorks = document.querySelector('#nav-works');
      // console.log("start observer");
      const options = {
        root: null,
        threshold: 0,
        rootMargin: "-70px"
      };
      const observer = new IntersectionObserver(function(entries, observer){    
        entries.forEach(entry => {
          // Page top navi intersecting check
          if(entry.target.classList.contains('intro')){
            if (!entry.isIntersecting && window.scrollY > 400) {
              navPageTop.classList.add('v-active');
              navWorks.classList.add('v-active');
            }else{
              navPageTop.classList.remove('v-active');
              navWorks.classList.remove('v-active');
            }
          }
          // Others intersecting check
          else{
            if (entry.isIntersecting){
              entry.target.classList.add('v-active');
              observer.unobserve(entry.target);
            }else{
              entry.target.classList.remove('v-active');
            }  
          }    
        });
      }, options);
      
      sections.forEach(section => {
        observer.observe(section);  
      });
    },
    scroll: function(){
      // New Smooth Scroll // No safari..
      const links = document.querySelectorAll(".scroll");

      for (const link of links) {
        link.addEventListener("click", clickHandler);
      }
      
      function clickHandler(e) {
        e.preventDefault();
        const href = this.getAttribute("href");
        const offsetTop = document.querySelector(href).offsetTop;
      
        scroll({
          top: offsetTop,
          behavior: "smooth"
        });
      }
    },
    accordion: function(){
      const panels = document.querySelectorAll('.accordion .panel');

      panels.forEach(panel => {
        panel.addEventListener('click', (e) => {
          const desc = e.currentTarget.querySelector('.description');
          const h = desc.scrollHeight;
          // console.log(h);
          if(e.currentTarget.classList.contains("active")){
            e.currentTarget.classList.remove("active");
            desc.style.maxHeight = `0px`;
          }else{
            e.currentTarget.classList.add("active");
            desc.style.maxHeight = `${h}px`;
          }
        });
      });
    }
  },
  computed: {
    textComputed: function() {
      return 'Computed: ' + this.firstName + ' ' + this.lastName
      // return console.log('unko2')
    }
  },
  beforeMount() {
    // this.hoge = "before mount Hoge"
    // console.log('beforemount: '+ this.hoge)
  },
  created() {
    // this.hoge = "Created  Hoge"
    // console.log('created: '+ this.hoge)
  },
  mounted(){
    this.hoge = "Mounted!!"
    console.log('mounted: '+ this.hoge)

    this.preloader()
    this.overlay()
    // this.observer()
    this.scroll()

    // this.$nextTick(function () {
    //   this.observer()
    // })
    

    // console.log('mounted')
    
  },
  beforeRouteUpdate (to, from, next) {
    console.log("Before Route Updates...!!!!!");
    next();
  }
}

</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active{
  transition: all .4s ease-out;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  // filter: blur(4px);
}
// .fade-enter-to{
//   opacity: .4;
//   filter: blur(4px);
// }
</style>
