<template>
  <main class="main contact">

    <!-- <NavExtra /> -->
    
    <section class="container section intro v-animate mb-0"> 
      <div class="intro-inner">
        <h1 class="text-outline v-animate v-move-in">Let’s Create something great!</h1>
        <div class="l-grid mb-2 v-animate v-move-in delay-1">
          <div class="col-l">
            <p class="text-title">Available for freelance work. Drop me an email or send me message by the form.</p>
          </div>
        </div>
        <div class="l-grid v-animate v-fade-in">
          <div class="col-l">
            <h2 class="h-5">Contact</h2>
            <a class="link link-slot h-4" href="mailto:creative.geek@gmail.com"><span data-text="creative.geek@gmail.com" class="link-text">creative.geek@gmail.com</span></a>
          </div>
          <div class="col-r">
            <h2 class="h-5">Social</h2>
            <ul class="social-list">
              <li class="nav-menu">
                <a href="#" class="link" target="blank">LN</a>
              </li>
              <li class="nav-menu">
                <a href="#" class="link" target="blank">IG</a>
              </li>
              <li class="nav-menu">
                <a href="#" class="link" target="blank">FB</a>
              </li>
              <li class="nav-menu">
                <a href="#" class="link" target="blank">TW</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="container section message"> 
      <div class="message-inner v-animate v-fade-in">
        <form class="contact-form" action="">
          <fieldset>
            <label for="user-name" class="form-label">Name</label>
            <span>
              <input type="text" name="user-name" id="user-name" size="50" placeholder="Enter your name here" class="form-input">
            </span>
          </fieldset>
          <fieldset>
            <label for="user-name" class="form-label">Email</label>
            <span>
              <input type="email" name="user-email" id="user-email" size="50" placeholder="Enter your email here" class="form-input">
            </span>
          </fieldset>
          <fieldset>
            <label for="user-name" class="form-label">Message</label>
            <span>
              <textarea name="message" id="message" col="50" row="10" placeholder="Enter your project details here" class="form-textarea"></textarea>
            </span>
          </fieldset>
          <div>
            <span></span>
            <span>
              <input type="submit" value="Submit" class="btn">
            </span>
          </div>
        </form>
      </div>
    </section> 

    <section class="container-fluid section">
      <div class="ticker ticker-main">
        <span>Thank you</span><span>Get in touch</span><span>Thank you</span><span>Get in touch</span><span>Thank you</span><span>Get in touch</span><span>Thank you</span><span>Get in touch</span><span>Thank you</span><span>Get in touch</span>
      </div>
    </section>
    
  </main>
</template>

<script>
// import NavExtra from'./NavExtra.vue'

export default {
  name: 'Page-Contact',
  components: {
    // NavExtra
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String
  },
  mounted(){
    this.observer()
    // this.overlay()
    this.scroll()
  }
}

</script>

<style>



</style>