<template>
  <main class="main about">

    <!-- <NavExtra /> -->
    <Cta />
    
    <section class="container section intro v-animate mb-0"> 
      <div class="intro-inner">
        Child: {{firstName}}
        <button @click="test()">test button</button>
        <h1 class="text-outline v-animate v-move-in">15+ year Design Experience</h1>
        <div class="l-grid mb-1">
          <div class="col-l offset">
            <p class="text-title v-animate v-move-in delay-1">Lorem ipsum dolor sit amet, are consetetur sadipscing elitr. </p>
          </div>
        </div>
        <div class="l-grid v-animate v-fade-in delay-2">
          <div class="col-r">
            <h2 class="h-5 v-animate v-draw-line-bottom">Subtitle</h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. .At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid section hero">
      <div class="hero-inner test-1" v-parallax="-0.26">  
        <img src="@/assets/images/about_img-hero.jpg" alt="">
      </div>
    </section>

    <section class="container section expertise">
      <h1 class="v-animate v-move-in v-draw-line-bottom">Expertise</h1>
      <div class="l-grid mb-1 v-animate v-fade-in">
        <div class="col-l offset">
          <p class="text-title">Lorem ipsum dolor sit amet, are consetetur sadipscing elitr. </p>
        </div>
        <div class="col-r">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </div>
      </div>

      <h2 class="h-5 v-animate v-fade-in v-draw-line-bottom mb-0">What I do</h2>
      <ul class="accordion mb-1">
        <li class="panel v-animate v-fade-in">
          <h3 class="label">Web Design1<span class="icon"></span></h3>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor indd viduntc Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor indd viduntc Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor indd viduntc Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </li>
        <li class="panel v-animate v-fade-in">
          <h3 class="label">Web Design1<span class="icon"></span></h3>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </li>
        <li class="panel v-animate v-fade-in">
          <h3 class="label">Web Design1<span class="icon"></span></h3>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </li>
        <li class="panel v-animate v-fade-in">
          <h3 class="label">Web Design1<span class="icon"></span></h3>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </li>
        <li class="panel v-animate v-fade-in">
          <h3 class="label">Web Design1<span class="icon"></span></h3>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duoLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
        </li>
      </ul>

      <div class="l-grid mb-2 v-animate v-fade-in">
        <div class="col-r">
          <h2 class="h-5 v-animate v-draw-line-bottom">Subtitle</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
        </div>
      </div>

      <div class="l-grid skill">
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        <div class="col v-animate v-fade-in">
          <img class="icon" src="@/assets/images/icon_skill-design.svg" alt="">
          <h3 class="h-5">Design/Prototype</h3>
          <ul>
            <li>XD</li>
            <li>Sketch</li>
            <li>Figma</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>Procreate</li>
          </ul>
        </div>
        
      </div>
    </section> 


    <section class="container section career">
      <h1 class="v-animate v-move-in v-draw-line-bottom">Career</h1>


      <flickity ref="flickity" :options="flickityOptions" class="main-carousel career-inner v-animate v-fade-in">
        <div class="col carousel-cell">
          <h2 class="year">2015 – 2016</h2>
          <h3 class="title">Graphic Designer at Fresh Digital Group</h3>
          <h4 class="location">New York, USA</h4>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
        </div>
        <div class="col carousel-cell">
          <h2 class="year">2016 – 2017</h2>
          <h3 class="title">Graphic Designer at Wallace Graphics</h3>
          <h4 class="location">New York, USA</h4>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
        </div>
         <div class="col carousel-cell">
          <h2 class="year">2018 – 2020</h2>
          <h3 class="title">Graphic Designer at Fresh Digital Group</h3>
          <h4 class="location">New York, USA</h4>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
        </div>
        <div class="col carousel-cell">
          <h2 class="year">2019 – 2021</h2>
          <h3 class="title">Graphic Designer at Wallace Graphics</h3>
          <h4 class="location">New York, USA</h4>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
        </div>
        <div class="col carousel-cell">
          <h2 class="year">2022 – 2029</h2>
          <h3 class="title">UI/UX Designer at Spark Labs</h3>
          <h4 class="location">New York, USA</h4>
          <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
        </div>

      </flickity>
    </section>

  <Contact />
   
    
  </main>
</template>

<script>
import Flickity from 'vue-flickity'
import Contact from './Contact.vue'
import Cta from './Cta.vue'
// import NavExtra from'./NavExtra.vue'

export default {
  name: 'About',
  components: {
    Flickity,
    Contact,
    Cta,
    // NavExtra
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: false,
        freeScroll: false,
        draggable: true,
        contain: true,
        cellAlign: 'left'
      }
    }
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String,
    accordion: Function,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
  },
  mounted(){
    // this.test()
    // this.overlay()
    this.observer()
    this.accordion()
    this.scroll()

  //   this.$nextTick(function () {
  //     this.observer()
  //  })
  }
}

</script>

<style>

</style>