<template>
  <main class="main work-temp">
    
    <!-- <NavExtra /> -->
    <Cta />
    
    <section class="container section intro v-animate mb-0"> 
      <div class="intro-inner l-grid">
        <h1>
          <p class="case-id text-outline v-animate v-move-in">
            Case<span>03</span>
          </p>
          <p class="v-animate v-move-in delay-1">Upflex Website</p>
        </h1>
        <div class="description v-animate v-fade-in delay-2">
          <p class="category">Website Design, Branding, Art Direction
          </p>
          <p class="date">2023</p>
        </div>
      </div>
    </section>


    <section class="container-fluid section hero">
      <div class="rellax hero-inner test-1" v-parallax="-0.24">  
        <img src="@/assets/images/works_case_img-hero.jpg" alt="">
      </div>
    </section>

    <section class="container section case">
      <div class="v-animate v-move-in v-draw-line-top case-outline l-grid">
        <div class="col-l">
          <p class="h-3">Yaroflasher is a motion design studio which makes very cool motion design.</p>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et eaLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea.<br><br>
          dolores et ea Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea</p>
        </div>
        <div class="col-r">
          <h3 class="h-5">Client</h3>
          <p>Upflex Inc.</p>
          <h3 class="h-5">Role</h3>
          <ul>
            <li>Art Direction</li>
            <li>Design</li>
            <li>Front-end</li>
          </ul>
          <h3 class="h-5">Year</h3>
          <p>2019-2020</p>
        </div>
      </div>
      
      <div class="showcase l-grid">
        <figure class="item i-s-l v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-2.jpg" alt="">
        </figure>
        <figure class="item i-m-r v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-m-l v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-2.jpg" alt="">
        </figure>
        <figure class="item i-s-r v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-l-c v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-xl-c v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
      </div>

      <h2 class="h-5 v-animate v-move-in v-draw-line-bottom">Mobile</h2>
      <div class="showcase l-grid">
        <figure class="item i-s-l v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-2.jpg" alt="">
        </figure>
        <figure class="item i-m-r v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-m-l v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-2.jpg" alt="">
        </figure>
        <figure class="item i-s-r v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-l-c v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
        <figure class="item i-xl-c v-animate v-fade-in">
          <img src="@/assets/images/works_case_img-3.jpg" alt="">
        </figure>
      </div>

    </section>



    <section class="container section next-work">
      <h2 class="h-5 v-animate v-move-in v-draw-line-bottom">Next</h2>
      <div class="next-inner v-animate v-fade-in">
        <nav class="nav nav-archives">
          <ul class="nav-list h-1">
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">
              Upflex Website<span class="arrow-outline"><img src="@/assets/images/icon_arrow-l.svg" alt=""></span>
              </router-link>
              <img class="img" src="@/assets/images/work-thumb-1.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </section>


    <Contact />
    
  </main>
</template>

<script>
import Contact from './Contact.vue'
import Cta from './Cta.vue'
// import NavExtra from'./NavExtra.vue'

export default {
  name: 'WorkTemplate',
  components: {
    Contact,
    Cta,
    // NavExtra
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String
  },
  mounted(){
    this.observer()
    // this.overlay()
    this.scroll()
  }

}

</script>

<style>

</style>