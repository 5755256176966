<template>
  <main class="main works">
    
    <!-- <NavExtra /> -->
    <Cta />

    <section class="container section intro v-animate mb-0"> 
      <div class="intro-inner l-grid">
        {{firstName}}
        <h1>
          <p class="case-id text-outline v-animate v-move-in">
            Lorem ipsum
          </p>
          <p class="v-animate v-move-in delay-1">Featured Works</p>
        </h1>
        <div class="description v-animate v-fade-in delay-2">
          <p class="category">Various works from Website/App Design, Branding, Mothing Graphis, Art Direction, to Front-end Development
          </p>
        </div>
      </div>
    </section>

    <section class="container section works">
    
      <div class="showcase l-grid">
        <router-link to="/work-template" class="item i-m-l v-animate v-fade-in" v-parallax="-0.05">
          <h2 class="title">Upflex Website 1</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-s-r v-animate v-fade-in" v-parallax="-0.2">
          <h2 class="title">Upflex Website 2</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in">
          <h2 class="title">Upflex Website 3</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-s-l v-animate v-fade-in" v-parallax="-0.05">
          <h2 class="title">Upflex Website 4</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-m-r v-animate v-fade-in" v-parallax="-0.1">
          <h2 class="title">Upflex Website 5</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in">
          <h2 class="title">Upflex Website 6</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>

        <router-link to="/work-template" class="item i-m-l v-animate v-fade-in" v-parallax="-0.05">
          <h2 class="title">Upflex Website 1</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-s-r v-animate v-fade-in" v-parallax="-0.2">
          <h2 class="title">Upflex Website 2</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in">
          <h2 class="title">Upflex Website 3</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-s-l v-animate v-fade-in" v-parallax="-0.05">
          <h2 class="title">Upflex Website 4</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-1.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-m-r v-animate v-fade-in" v-parallax="-0.1">
          <h2 class="title">Upflex Website 5</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-2.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
        <router-link to="/work-template" class="item i-l-c v-animate v-fade-in">
          <h2 class="title">Upflex Website 6</h2>
          <div class="visual"><img src="@/assets/images/work-thumb-3.jpg" alt=""></div>
          <div class="description v-animate v-draw-line-bottom"><span class="category">Website Design</span><span class="date">2020</span></div>
        </router-link>
             
      </div>
    </section>

    <Contact />
    
  </main>
</template>

<script>
import Contact from './Contact.vue'
import Cta from './Cta.vue'
// import NavExtra from'./NavExtra.vue'


export default {
  name: 'Works',
  components: {
    Contact,
    Cta,
    // NavExtra
  },
  props: {
    // overlay: Function,
    observer: Function,
    scroll: Function,
    test: Function,
    firstName: String
  },
  created(){
    // this.observer()
  },
  mounted(){
    // this.$nextTick(function () {
    //   this.observer()
    // })
    this.observer()
    // this.overlay()
    this.scroll()
  }
}

</script>

<style>

</style>