import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import { store } from './store/store'
import './assets/css/style.scss'
import './assets/js/main-2.js'
import VueParallaxJs from 'vue-parallax-js'
const options = {
  minWidth:768, 
  className:'v-parallax',
}
import PageHome from './components/Page-Home.vue'
import PageWorks from './components/Page-Works.vue'
import PageWorkTemplate from './components/Page-Work-Template.vue'
import PageAbout from './components/Page-About.vue'
import PageContact from './components/Page-Contact.vue'
import PageTest from './components/Page-Test.vue'
import PageTestChild from './components/Page-TestChild.vue'

Vue.use(VueRouter)
Vue.use(VueParallaxJs, options)

const router = new VueRouter({
  routes: [
    { path: '/', component: PageHome },
    { path: '/works', component: PageWorks },
    { path: '/work-template', component: PageWorkTemplate },
    { path: '/about', component: PageAbout },
    { path: '/contact', component: PageContact },
    { path: '/test', component: PageTest },
    { path: '/test-child', component: PageTestChild }
  ],
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    let position = { x: 0, y: 0 }
    if (savedPosition) {
      return savedPosition
    } 
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 400)
    })
  }
})

Vue.config.productionTip = false

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app')
