<template>
  <main class="main test-child">

    <section class="container section">

      <p>
        <transition name="fade" mode="out-in">
          <router-link to="/test">Test Link</router-link>
        </transition>
      </p>

      
     <div>
      <h1>{{demo}}</h1>
      <p>This is the test child template... sorry</p>
      <p>{{ppp}}  _____should be testtochild before..</p>


      <div class="product">
        <h4>Product List from Store JS</h4>
        <ul>
          <li v-for="product in saleProducts" :key="product.name">
            <span class="name">{{product.name}}</span>
            <span class="price">${{product.price}}</span>
          </li>
        </ul>
      </div>

     </div>
    </section>


  </main>
</template>

<script>

export default {
  name: 'TestChild',
  components: {
  },
  data() {
    return {
      demo: '{demo}Test Child',
    }
  },
  computed: {
    products(){
      return this.$store.state.products
    },
    saleProducts(){
      return this.$store.getters.saleProducts
    },
  },
  props: {
    ppp: Array
  },
  methods: {
    
  }
}

</script>

<style scoped lang="scss">
.main{
  background: #800921;
  padding: 130px 0 30px;
}
.product{
  span{
    margin-right: 1em;
  }
}
</style>
