import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
     products: [
       {name: 'Banana Skin', price: 20},
       {name: 'Shiny Star', price: 40},
       {name: 'Green Shells', price: 60},
       {name: 'Red Shells', price: 80}
     ]
  },
  getters: {
     saleProducts: state => {
      var saleProducts = state.products.map(product => {
        return {
          name: '**Sale** ' + product.name + ' **',
          price: product.price / 2
        }
      })
      return saleProducts
     },
     specialProducts: state => {
      var saleProducts = state.products.map(product => {
        return {
          name: '**SP** ' + product.name + ' **',
          price: product.price * 10
        }
      })
      return saleProducts
     }
  },
  mutations: {
    reducePrice: (state, payload) => {
      state.products.forEach(product => {
        product.price -= payload
      })
    }
    // reducePrice: state => {
    //   setTimeout(() => {
    //     state.products.forEach(product => {
    //       product.price -= 1
    //     })
    //   }, 3000)
    // }
  },
  actions: {
    reducePrice: (context, payload) => {
      setTimeout(() => {
        context.commit('reducePrice', payload)
      }, 1000)
    },
    specialPrice: (context, payload) => {
      payload *= 10
      setTimeout(() => {
        context.commit('reducePrice', payload)
      }, 1000)
    }
  }
})