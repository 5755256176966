<template>
   <section class="container section contact"> 
      <h1 class="v-animate v-move-in v-draw-line-bottom">
       <router-link to="/contact" class="link link-slot">
          <span class="link-text" data-text="Let’s Create">Let’s Create</span>
          <span class="arrow-outline"><img src="@/assets/images/icon_arrow-l.svg" alt=""></span>
       </router-link>
      </h1>
      <div class="l-grid">
        <div class="col-l col-title">
          <h2 class="h-5">Contact</h2>
          <a class="link link-slot" href="mailto:creative.geek@gmail.com"><span data-text="creative.geek@gmail.com" class="link-text">creative.geek@gmail.com</span></a>
        </div>
        <div class="col-r">
          <h2 class="h-5">Social</h2>
          <ul class="social-list">
            <li class="nav-menu">
              <a href="#" class="link" target="blank">LN</a>
            </li>
            <li class="nav-menu">
              <a href="#" class="link" target="blank">IG</a>
            </li>
            <li class="nav-menu">
              <a href="#" class="link" target="blank">FB</a>
            </li>
            <li class="nav-menu">
              <a href="#" class="link" target="blank">TW</a>
            </li>
        </ul>
        </div>
      </div>
    
    </section>
</template>

<script>
export default {
  name: 'Contact',
  components: {
    
  }
}

</script>

<style>

</style>