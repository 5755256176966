<template>

  <footer class="footer container">
    <section class="footer-inner v-animate v-move-in v-draw-line-top"> 
        <small class="copyright">Jiro Takahashi © 2020</small>
    </section>
  </footer>

</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<style>

</style>