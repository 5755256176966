<template>
  <aside>
    <div id="menu-overlay" class="overlay overlay-menu">
      <div class="overlay-content container">
        <div class="col-l">
          <nav class="nav">
            <ul class="nav-list h-1">
              <li class="nav-menu">
                <router-link to="/" class="link text-outline link-slot">
                <span data-text="Home" class="link-text">Home</span></router-link>
              </li>
              <li class="nav-menu">
                <router-link to="/works" class="link text-outline link-slot">
                <span data-text="Works" class="link-text">Works</span></router-link>
              </li>
              <li class="nav-menu">
                <router-link to="/about" class="link text-outline link-slot">
                <span data-text="About" class="link-text">About</span></router-link>
              </li>
              <li class="nav-menu">
                <router-link to="/contact" class="link text-outline link-slot">
                <span data-text="Contact" class="link-text">Contact</span></router-link>
              </li>
              <li class="nav-menu">
                <router-link to="/test" class="link text-outline link-slot">
                <span data-text="Test" class="link-text">Test</span></router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-r">
    
          <div class="info">
            <h3 class="title">Contact</h3>
            <ul>
              <li>
                <a class="link link-slot" href="mailto:creative.geek@gmail.com"><span data-text="creative.geek@gmail.com" class="link-text">creative.geek@gmail.com</span></a>
              </li>
            </ul>
          </div>
    
          <div class="info">
            <h3 class="title">Social</h3>
            <ul>
              <li>
                <a class="link link-slot" href="#" target="_blank"><span data-text="Instagram" class="link-text">Instagram</span></a>
              </li>
              <li>
                <a class="link link-slot" href="#" target="_blank"><span data-text="Facebook" class="link-text">Facebook</span></a>
              </li>
              <li>
                <a class="link link-slot" href="#" target="_blank"><span data-text="LinkedIn" class="link-text">LinkedIn</span></a>
              </li>
            </ul>
          </div>
    
          <div class="info">
            <h3 class="title">Base</h3>
            <address>Tokyo / New York / Anyware</address>
          </div>
  
        </div>
      </div>
    
    </div>
  
    <div id="archives-overlay" class="overlay overlay-archives">
      <div class="overlay-content container">
        <nav class="nav nav-archives">
          <ul class="nav-list h-1">
  
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Website</router-link>
              <img class="img" src="@/assets/images/work-thumb-1.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex App</router-link>
              <img class="img" src="@/assets/images/work-thumb-2.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Dashboard</router-link>
              <img class="img" src="@/assets/images/work-thumb-3.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                </div>
              </div>
            </li>
  
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Website</router-link>
              <img class="img" src="@/assets/images/work-thumb-1.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex App</router-link>
              <img class="img" src="@/assets/images/work-thumb-2.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Dashboard</router-link>
              <img class="img" src="@/assets/images/work-thumb-3.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                </div>
              </div>
            </li>

            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Website</router-link>
              <img class="img" src="@/assets/images/work-thumb-1.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                  <span>Upflex Website</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex App</router-link>
              <img class="img" src="@/assets/images/work-thumb-2.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                  <span>Upflex App</span>
                </div>
              </div>
            </li>
            <li class="nav-menu">
              <router-link to="/work-template" class="link text-outline">Upflex Dashboard</router-link>
              <img class="img" src="@/assets/images/work-thumb-3.jpg"/>
              <div class="ticker">
                <div class="ticker-content">
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                  <span>Upflex Dashboard</span>
                </div>
              </div>
            </li>
  
          </ul>
        </nav>
      </div>
    
    </div>
  
    <div class="logo-wrapper">
      <router-link to="/" class="logo-link">
        <img class="svg" src="@/assets/images/logo.svg" alt="Jiro Takahashi">
      </router-link>
    </div>
  
    <div id="menu-btn" class="menu-btn">
      <span class="visuallyhidden">Menu</span>
      <span class="line-1"></span>
      <span class="line-2"></span>
    </div>
  
    <nav id="nav-extra" class="nav-extra">
      <nav id="nav-works" class="nav nav-works v-animate v-fade-in">
        <a href="#" class="link link-slot"><span data-text="Archives" class="link-text">Archives</span></a>
        <a href="#" class="link link-slot d-none"><span data-text="Close" class="link-text">Close</span></a>
      </nav>
      <nav id="nav-pagetop" class="nav nav-pagetop v-animate v-fade-in">
        <a href="#top" class="link scroll link-slot"><span data-text="Page Top" class="link-text">Page Top</span></a>
      </nav>
    </nav>
    
  </aside>
</template>

<script>
export default {
  name: 'Aside',
  props: {
    // observer: Function
  },
  // mounted(){
  //   this.observer()
  // }
}
</script>

<style scoped lang="scss">
// .cta{
//   // margin-bottom: -7em;
//   bottom: -5rem;
// }
</style>