<template>

  <header class="header">
    <div class="container">
      <nav class="nav-main v-animate v-move-in v-draw-line-bottom">
        <h1 class="author">
          <router-link to="/" class="link link-slot"><span data-text="Jiro Takahashi" class="link-text">Jiro Takahashi</span></router-link>
        </h1>
        <ul class="nav-list">
          <li class="nav-menu">
            <router-link to="/works" class="link link-slot"><span data-text="Works" class="link-text">Works</span></router-link>
          </li>
          <li class="nav-menu">
            <router-link to="/work-template" class="link link-slot"><span data-text="Works" class="link-text">Work T</span></router-link>
          </li>
          <li class="nav-menu">
            <router-link to="/about" class="link link-slot"><span data-text="About" class="link-text">About</span></router-link>
          </li>
          <li class="nav-menu">
            <router-link to="/contact" class="link link-slot"><span data-text="Contact" class="link-text">Contact</span></router-link>
          </li>
          <li class="nav-menu">
            <router-link to="/test" class="link link-slot"><span data-text="Test" class="link-text">Test</span></router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
  //  observer: Function,
  },
  // mounted(){
  //   this.observer()
  // }
}
</script>

<style>

</style>